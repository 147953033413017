/* eslint-disable @mate-academy/frontend/restrict-window-usage */

import {
  type ChildDimensions,
  HorizontalPositionMode,
  TooltipOrientation,
  VerticalPositionMode,
} from '@/components/ui/ToolTip/tooltip.typedefs';
import { EMPTY_OBJECT } from '@/constants';

const HORIZONTAL_POINTER_OFFSET = 20;
const VERTICAL_OFFSET = 8;
const HORIZONTAL_OFFSET = 4;

interface Params {
  parent: HTMLElement | null;
  child: ChildDimensions;
  orientation: TooltipOrientation;
  verticalPositionMode: VerticalPositionMode;
  horizontalPositionMode: HorizontalPositionMode;
}

interface VerticalPositionOptions {
  parentTop: number;
  parentHeight: number;
  childHeight: number;
  mode: VerticalPositionMode;
  orientation: TooltipOrientation;
}

interface HorizontalPositionOptions {
  parentLeft: number;
  parentWidth: number;
  childWidth: number;
  mode: HorizontalPositionMode;
  orientation: TooltipOrientation;
}

interface Position {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
}

interface GetTooltipPositionResult {
  position: Position;
  actualHorizontalPositionMode: HorizontalPositionMode;
  actualVerticalPositionMode: VerticalPositionMode;
}

export const getTooltipPosition = (
  params: Params,
): GetTooltipPositionResult => {
  const {
    parent,
    child,
    orientation,
    verticalPositionMode,
    horizontalPositionMode,
  } = params;

  if (!parent || !child || !window) {
    return {
      position: EMPTY_OBJECT,
      actualHorizontalPositionMode: horizontalPositionMode,
      actualVerticalPositionMode: verticalPositionMode,
    };
  }

  const {
    top: parentTop,
    left: parentLeft,
    height: parentHeight,
    width: parentWidth,
  } = parent.getBoundingClientRect();

  const {
    height: childHeight,
    width: childWidth,
  } = child;

  const {
    mode: actualHorizontalPositionMode,
    ...horizontalPosition
  } = getHorizontalPosition({
    parentLeft,
    parentWidth,
    childWidth,
    mode: horizontalPositionMode,
    orientation,
  });
  const {
    mode: actualVerticalPositionMode,
    ...verticalPosition
  } = getVerticalPosition({
    parentTop,
    parentHeight,
    childHeight,
    mode: verticalPositionMode,
    orientation,
  });

  return {
    position: {
      ...horizontalPosition,
      ...verticalPosition,
    },
    actualHorizontalPositionMode,
    actualVerticalPositionMode,
  };
};

function getHorizontalPosition(options: HorizontalPositionOptions) {
  const { orientation, mode, childWidth } = options;

  if (orientation === TooltipOrientation.Aside) {
    return getAsideOrientationHorizontalPosition(options);
  }

  switch (mode) {
    case HorizontalPositionMode.LeftSide: {
      const leftPosition = getLeftHorizontalPosition(options);
      const willBeCroppedAtLeft = leftPosition.left < 0;

      if (willBeCroppedAtLeft) {
        return getRightHorizontalPosition(options);
      }

      return leftPosition;
    }

    case HorizontalPositionMode.RightSide: {
      const rightPosition = getRightHorizontalPosition(options);
      const willBeCroppedAtRight = (
        rightPosition.left + childWidth
      ) > window.innerWidth;

      if (willBeCroppedAtRight) {
        return getLeftHorizontalPosition(options);
      }

      return getRightHorizontalPosition(options);
    }

    case HorizontalPositionMode.Center:
    default: {
      const centerPosition = getCenterHorizontalPosition(options);
      const willBeCroppedAtLeft = centerPosition.left < 0;
      const willBeCroppedAtRight = (
        centerPosition.left + childWidth
      ) > window.innerWidth;

      if (willBeCroppedAtLeft) {
        return getRightHorizontalPosition(options);
      }

      if (willBeCroppedAtRight) {
        return getLeftHorizontalPosition(options);
      }

      return getCenterHorizontalPosition(options);
    }
  }
}

function getCenterHorizontalPosition({
  parentLeft,
  parentWidth,
  childWidth,
}: HorizontalPositionOptions) {
  return {
    left: parentLeft + parentWidth / 2 - childWidth / 2,
    mode: HorizontalPositionMode.Center,
  };
}

function getLeftHorizontalPosition({
  parentLeft,
  parentWidth,
  childWidth,
}: HorizontalPositionOptions) {
  return {
    left: parentLeft + parentWidth / 2 - childWidth + HORIZONTAL_POINTER_OFFSET,
    mode: HorizontalPositionMode.LeftSide,
  };
}

function getRightHorizontalPosition({
  parentLeft,
  parentWidth,
}: HorizontalPositionOptions) {
  return {
    left: parentLeft + parentWidth / 2 - HORIZONTAL_POINTER_OFFSET,
    mode: HorizontalPositionMode.RightSide,
  };
}

function getVerticalPosition(
  options: VerticalPositionOptions,
) {
  const { orientation, mode, childHeight } = options;

  if (orientation === TooltipOrientation.Aside) {
    return getAsideOrientationVerticalPosition(options);
  }

  switch (mode) {
    case VerticalPositionMode.Bottom: {
      const bottomPosition = getBottomVerticalPosition(options);
      const willBeCroppedAtBottom = (
        bottomPosition.top + childHeight
      ) > window.innerHeight;

      if (willBeCroppedAtBottom) {
        return getTopVerticalPosition(options);
      }

      return bottomPosition;
    }

    case VerticalPositionMode.Top:
    default: {
      const topPosition = getTopVerticalPosition(options);
      const willBeCroppedAtTop = topPosition.top < 0;

      if (willBeCroppedAtTop) {
        return getBottomVerticalPosition(options);
      }

      return getTopVerticalPosition(options);
    }
  }
}

function getTopVerticalPosition({
  parentTop,
  childHeight,
}: VerticalPositionOptions) {
  return {
    top: parentTop - childHeight - VERTICAL_OFFSET + window.scrollY,
    mode: VerticalPositionMode.Top,
  };
}

function getBottomVerticalPosition({
  parentTop,
  parentHeight,
}: VerticalPositionOptions) {
  return {
    top: parentTop + parentHeight + VERTICAL_OFFSET + window.scrollY,
    mode: VerticalPositionMode.Bottom,
  };
}

function getAsideOrientationVerticalPosition(
  options: VerticalPositionOptions,
) {
  const { mode, childHeight } = options;

  switch (mode) {
    case VerticalPositionMode.Bottom: {
      const bottomPosition = getAsideBottomPosition(options);
      const willBeCroppedAtBottom = (
        bottomPosition.top + childHeight
      ) > window.innerHeight;

      if (willBeCroppedAtBottom) {
        return getAsideTopPosition(options);
      }

      return bottomPosition;
    }

    case VerticalPositionMode.Top:
    default: {
      const topPosition = getAsideTopPosition(options);
      const willBeCroppedAtTop = topPosition.top < 0;

      if (willBeCroppedAtTop) {
        return getAsideBottomPosition(options);
      }

      return topPosition;
    }
  }
}

function getAsideTopPosition({
  parentTop,
  parentHeight,
  childHeight,
}: VerticalPositionOptions) {
  return {
    top: parentTop + parentHeight - childHeight,
    mode: VerticalPositionMode.Top,
  };
}

function getAsideBottomPosition({
  parentTop,
}: VerticalPositionOptions) {
  return {
    top: parentTop,
    mode: VerticalPositionMode.Bottom,
  };
}

function getAsideOrientationHorizontalPosition(
  options: HorizontalPositionOptions,
) {
  const { mode, childWidth } = options;

  switch (mode) {
    case HorizontalPositionMode.LeftSide: {
      const leftPosition = getAsideLeftPosition(options);
      const willBeCroppedAtLeftSide = (
        leftPosition.left < 0
      );

      if (willBeCroppedAtLeftSide) {
        return getAsideRightPosition(options);
      }

      return leftPosition;
    }

    case HorizontalPositionMode.RightSide:
    case HorizontalPositionMode.Center:
    default: {
      const topPosition = getAsideRightPosition(options);
      const willBeCroppedAtRightSide = (
        topPosition.left + childWidth
      ) > window.innerWidth;

      if (willBeCroppedAtRightSide) {
        return getAsideLeftPosition(options);
      }

      return topPosition;
    }
  }
}

function getAsideLeftPosition({
  parentLeft,
  childWidth,
}: HorizontalPositionOptions) {
  return {
    left: parentLeft - childWidth - HORIZONTAL_OFFSET,
    mode: HorizontalPositionMode.LeftSide,
  };
}

function getAsideRightPosition({
  parentLeft,
  parentWidth,
}: HorizontalPositionOptions) {
  return {
    left: parentLeft + parentWidth + HORIZONTAL_OFFSET,
    mode: HorizontalPositionMode.RightSide,
  };
}
