import {
  type FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { cn } from '@/lib';
import { Portal } from '@/components/ui/Portal';
import { widthModeClassNames } from '@/components/ui/ToolTip/constants';
import {
  useThrottledAndDebouncedCallback,
} from '@/hooks/useThrottledAndDebouncedCallback';
import { emptyFunction } from '@/lib/helpers/functional';
import {
  HorizontalPositionMode,
  TooltipOrientation,
  ToolTipWidthMode,
  type TooltipWithPortalProps,
  VerticalPositionMode,
} from '../tooltip.typedefs';
import { getTooltipPosition } from './TooltipWithPortal.helpers';
import portalTooltipStyles from './TooltipWithPortal.module.scss';
import tooltipStyles from '../ToolTip.module.scss';

type Props = TooltipWithPortalProps & {
  parentElement: HTMLElement | null;
};

const horizontalPositionModeClasses: Record<HorizontalPositionMode, string> = {
  [HorizontalPositionMode.LeftSide]: portalTooltipStyles.leftSideMode,
  [HorizontalPositionMode.Center]: portalTooltipStyles.centerMode,
  [HorizontalPositionMode.RightSide]: portalTooltipStyles.rightSideMode,
};

const verticalPositionModeClasses: Record<VerticalPositionMode, string> = {
  [VerticalPositionMode.Top]: portalTooltipStyles.topMode,
  [VerticalPositionMode.Bottom]: portalTooltipStyles.bottomMode,
};

const verticalPositionModeAsideClasses: Record<VerticalPositionMode, string> = {
  [VerticalPositionMode.Top]: portalTooltipStyles.topAsideMode,
  [VerticalPositionMode.Bottom]: portalTooltipStyles.bottomAsideMode,
};

const horizontalPositionModeAsideClasses: Record<
  HorizontalPositionMode, string
> = {
  [HorizontalPositionMode.LeftSide]: portalTooltipStyles.leftAsideMode,
  [HorizontalPositionMode.RightSide]: portalTooltipStyles.rightAsideMode,
  [HorizontalPositionMode.Center]: '',
};

export const TooltipContent: FC<Props> = ({
  text: tooltipContent,
  parentElement,
  widthMode = ToolTipWidthMode.Min,
  verticalPosition = VerticalPositionMode.Top,
  horizontalPosition = HorizontalPositionMode.Center,
  orientation = TooltipOrientation.Center,
  applyDefaultStylesForCustomElement = false,
  messageClassName,
}) => {
  const [childRef, setChildRef] = useState<HTMLDivElement | null>(null);

  const [childDimensions, setChildDimensions] = useState({
    width: 0,
    height: 0,
  });

  const setThrottledChildDimensions = useThrottledAndDebouncedCallback(
    setChildDimensions,
    50,
  );

  const {
    position,
    actualHorizontalPositionMode,
    actualVerticalPositionMode,
  } = useMemo(() => (
    getTooltipPosition({
      verticalPositionMode: verticalPosition,
      horizontalPositionMode: horizontalPosition,
      orientation,
      parent: parentElement,
      child: childDimensions,
    })
  ), [
    parentElement,
    horizontalPosition,
    orientation,
    verticalPosition,
    childDimensions,
  ]);

  const tooltipClasses = cn(
    tooltipStyles.tooltipBase,
    tooltipStyles.renderedInPortal,
    tooltipStyles.tooltipPointer,
    widthModeClassNames[widthMode],
    messageClassName,
    {
      [horizontalPositionModeAsideClasses[actualHorizontalPositionMode]]: (
        orientation === TooltipOrientation.Aside
      ),
      [verticalPositionModeAsideClasses[actualVerticalPositionMode]]: (
        orientation === TooltipOrientation.Aside
      ),
      [horizontalPositionModeClasses[actualHorizontalPositionMode]]: (
        orientation === TooltipOrientation.Center
      ),
      [verticalPositionModeClasses[actualVerticalPositionMode]]: (
        orientation === TooltipOrientation.Center
      ),
    },
  );

  const tooltipElement = useMemo(() => (
    typeof tooltipContent === 'string'
      ? (
        <div
          data-qa="tooltip-content"
          className={tooltipClasses}
        >
          {tooltipContent}
        </div>
      )
      : (
        <div
          data-qa="tooltip-content"
          className={
            cn({ [tooltipClasses]: applyDefaultStylesForCustomElement })
          }
        >
          {tooltipContent}
        </div>
      )
  ), [tooltipClasses, tooltipContent, applyDefaultStylesForCustomElement]);

  useEffect(() => {
    if (!childRef) {
      return emptyFunction;
    }

    const {
      width: initialWidth,
      height: initialHeight,
    } = childRef.getBoundingClientRect();

    setChildDimensions({
      width: initialWidth,
      height: initialHeight,
    });

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        const childRect = childRef.getBoundingClientRect();

        setThrottledChildDimensions({
          width: childRect.width,
          height: childRect.height,
        });
      });
    });

    resizeObserver.observe(childRef);

    return () => {
      resizeObserver.disconnect();
    };
  }, [childRef, setThrottledChildDimensions]);

  return (
    <Portal>
      <div
        className={portalTooltipStyles.tooltipWrapper}
        style={position}
        ref={setChildRef}
      >
        {tooltipElement}
      </div>
    </Portal>
  );
};
